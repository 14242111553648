import { checkOnLocalhost } from '../aws/js/portal/environment';

export default function IdcManagementPage() {
  const micro_services_name = 'aws-customer-portal-idc-management';
  const production_js_path = `/micro_services/${micro_services_name}/${micro_services_name}.js`;
  const development_js_path = `http://localhost:4001/${micro_services_name}.js`;

  const page = (
    <>
      {!checkOnLocalhost() && <script defer="defer" src={production_js_path}></script>}
      {checkOnLocalhost() && <script defer="defer" src={development_js_path}></script>}
      <div id={micro_services_name}></div>
    </>
  );

  function onPageReady() {}

  return [page, onPageReady];
}
