import { loadPage } from './navigation';
import swal from 'sweetalert';
import { linkWithoutSearch, linkReplaceWithHash } from './main';
import { get_feature_flag_property } from './feature_flags';

/**
 * Configure (hide/show) the sidebar items based on the users permissions and start the actual navigation process.
 *
 * @param {string} hash
 */
export async function startNavigationProcess(hash) {
  removeSpinners();

  // Load the new page into the main area of the portal
  await loadMain(hash, localStorage.permissions?.split(',') ?? []);
}

export function configureSidebar(sidebar) {
  let permissions = localStorage.permissions?.split(',') ?? [];
  sidebar.querySelectorAll('.sidebar-item').forEach(sidebarItem => {
    const itemPermissions =
      sidebarItem.id in process.env.PORTAL_CONFIG.menuPermissions
        ? process.env.PORTAL_CONFIG.menuPermissions[sidebarItem.id]
        : [];
    if (itemPermissions.length === 0 || itemPermissions.some(p => permissions.includes(p))) {
      sidebarItem.style.display = '';
    } else {
      sidebarItem.style.display = 'none';
    }
  });

  let promises = [];

  promises.push(configureSidebarFeatureFlags(sidebar));

  return Promise.all(promises);
}

function configureSidebarFeatureFlags(sidebar) {
  let promises = [];

  sidebar.querySelectorAll('.sidebar-item').forEach(sidebarItem => {
    // Skip if already hidden or not connected to a feature flag
    if (sidebarItem.style.display === 'none' || !sidebarItem.dataset.featureFlag) {
      return;
    }

    // Get feature flag and property name
    let featureFlag = sidebarItem.dataset.featureFlag;

    // Reverse (logical not: !feature.property)
    let reverse = false;
    if (featureFlag.startsWith('!')) {
      featureFlag = featureFlag.substr(1);
      reverse = true;
    }

    // Data
    promises.push(
      get_feature_flag_property(featureFlag)
        .then(function (featureFlag) {
          if ((!reverse && featureFlag) || (reverse && !featureFlag)) {
            sidebarItem.style.display = '';
          } else {
            sidebarItem.style.display = 'none';
          }
        })
        .catch(function (error) {
          console.error(error);
          sidebarItem.style.display = 'none';
        }),
    );
  });

  return Promise.all(promises);
}

function showActiveSidebarItem() {
  // take care of both /#dashboard and /dashboard
  const menuItems = document.location.hash
    ? $(`.sidebar-item > a[href="${linkWithoutSearch(document.location.hash)}"]`)
    : $(`.sidebar-item > a[href="${linkReplaceWithHash(window.location.pathname)}"]`);
  const activeItems = $('.sidebar-item-active');
  // take care of microservices
  const menuMicroServiceItems = $(`.sidebar-item > a[href="${window.location.pathname}"]`);

  activeItems.each(index => {
    activeItems[index].classList.remove('sidebar-item-active');
  });

  menuItems.each(index => {
    menuItems[index].classList.add('sidebar-item-active');
  });

  menuMicroServiceItems.each(index => {
    menuMicroServiceItems[index].classList.add('sidebar-item-active');
  });

  // Show all sidebar menus that contain the active link
  $('.sidebar-item .collapse:has(a.sidebar-item-active)').collapse('show');

  // Close all sidebar menus that don't contain an active link
  $('.sidebar-item .collapse.show:not(:has(a.sidebar-item-active))').collapse('hide');
}

async function loadMain(hash, permissions) {
  if (hash && permissions) {
    const anchorElement =
      document.querySelector(`.sidebar-item a[href="${linkWithoutSearch(hash)}"]`) ||
      document.querySelector(`.sidebar-item a[href="${hash}"]`) ||
      document.querySelector(`a.dropdown-item[href="${linkWithoutSearch(hash)}"]`) ||
      document.querySelector(`a.dropdown-item[href="${hash}"]`) ||
      document.querySelector(`.sidebar-item a[href="${window.location.pathname}"]`);

    if (!anchorElement) await loadPage('');

    let itemPermissions = [];
    itemPermissions =
      anchorElement && anchorElement.id in process.env.PORTAL_CONFIG.menuPermissions
        ? process.env.PORTAL_CONFIG.menuPermissions[anchorElement.id]
        : [];

    if (itemPermissions.length !== 0 && !itemPermissions.some(p => permissions.includes(p))) {
      removeSpinners();
      swal({
        title: 'Error:',
        text: "You don't have permissions to access this page. Let's navigate back to the dashboard!",
        icon: 'error',
      }).then(function () {
        window.location.hash = '#dashboard';
      });
    } else {
      await loadPage(hash);
      showActiveSidebarItem();
    }
  }
}

export function addTableSpinner() {
  $('.dataTables_processing').show();
}

export function addSpinner() {
  removeSpinners();

  // Find the sidebar menu item that has a href matching the current hash
  const menuItem = $('.sidebar-item > a[href="' + document.location.hash + '"]');

  $('<div class="loading-spinner"><div class="spinner-border spinner-border-sm" role="status"></div>').appendTo(
    menuItem.length ? menuItem : $('#sidebarItemDashboard > a'),
  );
}

export function addButtonSpinner(buttonId) {
  const submitButton = document.getElementById(buttonId ?? 'submitButton');
  if (submitButton) submitButton.classList.add('loading-animation');
}

export function addSpinners() {
  $('body').addClass('loading');
}

export function removeSpinners(keepLoading) {
  if (!keepLoading) {
    $('body').removeClass('loading');
  }
  $('.loading-spinner').remove();
  $('.dataTables_processing').hide();

  // Remove the `loading-animation` from all elements
  document.querySelectorAll('.loading-animation').forEach(el => {
    el.classList.remove('loading-animation');
  });

  const loading_form_inline = document.querySelectorAll('.loading-form-inline');
  [...loading_form_inline].forEach(form_loading => {
    form_loading.classList.remove('loading-animation');
  });
}

export function toggleSidebar() {
  document.getElementById('sidebar').classList.toggle('sidebar-collapse');
  document.getElementById('sidebar-close-button').classList.toggle('sidebar-collapse-close');
  document.getElementById('page-content-wrapper').classList.toggle('sidebar-collapse-main');
  document.getElementById('sidebar-close-button-icon').classList.toggle('fa-chevron-left');
  document.getElementById('sidebar-close-button-icon').classList.toggle('fa-chevron-right');
  $(window).trigger('resize');
}
