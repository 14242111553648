import swal from 'sweetalert';
import { CONF } from './environment';
import { Modal, Toast } from 'bootstrap';
import * as Prism from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-shell-session';
import 'prismjs/themes/prism.css';
import { addButtonSpinner, addSpinner, addSpinners, removeSpinners, toggleSidebar } from './sidebar';
import { apiAxios, baseApiAxios, showError } from './api';
import {
  DistributionListApi,
  DistributionListOperations,
  formatChildRowDistribuitonListMembers,
} from './distribution_list_wizard';
import DistributionListWizard from '../../_distribution_list_wizard';
import { TemporaryRoleComment } from '../../_temporary_role_comment';
import { networkInfoFields } from './networks';
import { trainingInfoFields } from './trainingsList';

export const accountInfoFields = [
  { Name: 'Account Id', Value: 'account_id' },
  { Name: 'Root Account', Value: 'account_root_email' },
  { Name: 'Friendly Name', Value: 'account_friendly_name' },
  { Name: 'Description', Value: 'description' },
  { Name: 'FPC Status', Value: 'fpc_status' },
  { Name: 'Account Area', Value: 'account_area' },
  { Name: 'Account Type', Value: 'account_type' },
  { Name: 'Account Stage', Value: 'account_stage' },
  { Name: 'AWS Support Plan', Value: 'support_plan' },
  { Name: 'IT Responsible', Value: 'it_responsible' },
  { Name: 'Primary Responsible', Value: 'primary_responsible' },
  { Name: 'Secondary Responsible', Value: 'sec_responsible' },
  { Name: 'Creation Date', Value: 'creation_date' },
];

export const alternateContactsInfoFields = [
  { Name: 'Contact', Value: 'Title' },
  { Name: 'Phone Number', Value: 'PhoneNumber' },
  { Name: 'Email', Value: 'EmailAddress' },
  { Name: 'Actions', Value: 'actions' },
];

export const costDataInfoFields = [
  { Name: 'APP ID', Value: 'app_id' },
  { Name: 'APPD ID', Value: 'appd_id' },
  { Name: 'APPD NAME', Value: 'appd_name' },
  { Name: 'CMDB ID', Value: 'cmdb_id' },
  { Name: 'Cost Center', Value: 'cost_center' },
  { Name: 'PSO/PSP', Value: 'psp' },
  { Name: 'IT Responsible', Value: 'it_responsible' },
  { Name: 'Status', Value: 'cost_data_status' },
];

export const sbusCloudroomInfoFields = [
  { Name: 'Data Source ID', Value: 'dataSourceID' },
  { Name: 'Environment', Value: 'environment' },
  { Name: 'ID', Value: 'ID' },
  { Name: 'Life Cycle Status', Value: 'lifeCycleStatus' },
];

export const accountOrgInfoFields = [
  { Name: 'Organizations Master Account', Value: 'master_account' },
  { Name: 'Organizations Id', Value: 'org_id' },
  { Name: 'Organizations Name', Value: 'org_name' },
  { Name: 'Organizations Unit Id', Value: 'org_ou_id' },
  { Name: 'Organizations Unit Name', Value: 'org_ou_name' },
  { Name: 'Organizations Account Status', Value: 'org_status' },
  { Name: 'Last Org Update (UTC)', Value: 'org_status_update' },
];

export const orderInfoFields = [
  { Name: 'Order Id', Value: 'id' },
  { Name: 'Action', Value: 'action' },
  { Name: 'Proposer', Value: 'proposer' },
  { Name: 'Creation Date', Value: 'creation_date' },
  { Name: 'Comment', Value: 'approval_comment' },
  { Name: 'Approved', Value: 'approved' },
  { Name: 'Approver', Value: 'approver' },
  { Name: 'Approval Date', Value: 'approval_date' },
  { Name: 'Executed', Value: 'executed' },
  { Name: 'Execution Date', Value: 'execution_date' },
  { Name: 'Execution Response', Value: 'execution_response' },
];

export const apiKeyInfoFields = [
  { Name: 'Api Key Id', Value: 'api_key_id' },
  { Name: 'Friendly Name', Value: 'custom_name' },
  { Name: 'Friendly Description', Value: 'custom_description' },
  { Name: 'Status', Value: 'status' },
  { Name: 'Enabled', Value: 'enabled' },
  { Name: 'Created Date', Value: 'created_date' },
  { Name: 'Last Update Date', Value: 'last_updated_date' },
];

export const apiKeyAdminInfoFields = [
  { Name: 'Api Key Id', Value: 'api_key_id' },
  { Name: 'Friendly Name', Value: 'custom_name' },
  { Name: 'Friendly Description', Value: 'custom_description' },
  { Name: 'Name', Value: 'name' },
  { Name: 'Description', Value: 'description' },
  { Name: 'Status', Value: 'status' },
  { Name: 'Enabled', Value: 'enabled' },
  { Name: 'Created Date', Value: 'created_date' },
  { Name: 'Last Update Date', Value: 'last_updated_date' },
];

export const apiKeyUsagePlanInfoFields = [
  { Name: 'Usage Plan Id', Value: 'usage_plan_id' },
  { Name: 'Usage Plan Name', Value: 'usage_plan_name' },
  { Name: 'Usage Plan Description', Value: 'usage_plan_description' },
  { Name: 'Usage Plan Quota Limit', Value: 'usage_plan_quota_limit' },
];

export const regionNames = {
  'us-east-1': 'N. Virginia',
  'us-east-2': 'Ohio',
  'us-west-1': 'N. California',
  'us-west-2': 'Oregon',
  'af-south-1': 'Cape Town',
  'ap-east-1': 'Hong Kong',
  'ap-south-1': 'Mumbai',
  'ap-northeast-3': 'Osaka',
  'ap-northeast-2': 'Seoul',
  'ap-southeast-1': 'Singapore',
  'ap-southeast-2': 'Sydney',
  'ap-northeast-1': 'Tokyo',
  'ca-central-1': 'Canada',
  'eu-central-1': 'Frankfurt',
  'eu-west-1': 'Ireland',
  'eu-west-2': 'London',
  'eu-west-3': 'Paris',
  'eu-south-1': 'Milan',
  'eu-north-1': 'Stockholm',
  'me-south-1': 'Bahrain',
  'sa-east-1': 'Sao Paulo',
  'cn-north-1': 'Bejing',
  'cn-northwest-1': 'Ningxia',
};

export const frontendConfigItemIds = {
  dxgw: 'sidebarItemListDXGWs',
};

export let allowedtoViewContent = {};
let pageScrollPosition = 0;

export function getWindowPosition() {
  pageScrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
}

export function linkWithoutSearch(hash) {
  return hash ? window.location.origin + '/' + hash : hash;
}

export function linkReplaceWithHash(pathname) {
  return window.location.origin + '/' + pathname.replace('/', '#');
}

function updateRegionsDropdown(regions, dropdownMenus) {
  removeSpinners();

  dropdownMenus.forEach(dropdownMenuId => {
    const select = $('#' + dropdownMenuId);

    select.empty().append(
      regions.map(region => {
        let optionContent =
          `<span class="bs-dropdown-item-text">${region.name}</span>` +
          `<span class="bs-dropdown-badge new">${region.id}</span>`;
        if (region.restriction_type === 'Denied') {
          optionContent += `<span class="bs-dropdown-badge">Future Deny List</span>`;
        }
        return $('<option>')
          .val(region.id)
          .attr('data-content', optionContent)
          .attr('data-restriction-type', region.restriction_type)
          .attr('selected', region.id === 'eu-central-1' || region.id === 'cn-north-1');
      }),
    );

    select.trigger('change');
  });

  $('.selectpicker').selectpicker('refresh');
  $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');
}

export async function showAndProcessRegionRestrictWarning(
  regionSelectId,
  acknowledgeDeniedRegionCheckId,
  selectedDeniedRegionCheckId,
) {
  let regionSelect = document.getElementById(regionSelectId);
  let acknowledgeDeniedRegionCheck = document.getElementById(acknowledgeDeniedRegionCheckId);
  let selectedDeniedRegionCheck = document.getElementById(selectedDeniedRegionCheckId);

  // check if restriction type is denied
  if (regionSelect.options[regionSelect.selectedIndex].dataset.restrictionType === 'Denied') {
    selectedDeniedRegionCheck.checked = true;
    await swal({
      title: 'Are you sure?',
      text: 'The region you selected will not be allowed in the future. Click OK to acknowledge that you will submit an order in denied regions, otherwise your order will be rejected.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willContinue => {
      if (willContinue) {
        acknowledgeDeniedRegionCheck.checked = true;
      } else {
        acknowledgeDeniedRegionCheck.checked = false;
      }
    });
  } else {
    selectedDeniedRegionCheck.checked = false;
  }
}

export function validateAcknowledgeDeniedRegion(acknowledgeDeniedRegionCheckId, selectedDeniedRegion) {
  let acknowledgeDeniedRegionCheck = document.getElementById(acknowledgeDeniedRegionCheckId);
  addSpinner();
  addButtonSpinner();
  return new Promise((resolve, reject) => {
    if (selectedDeniedRegion && !acknowledgeDeniedRegionCheck.checked) {
      swal({
        title: 'Error',
        text: 'You can not submit orders in denied regions without acknowledging it. Please click OK to acknowledge it and submit the order.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then(willContinue => {
        if (willContinue) {
          acknowledgeDeniedRegionCheck.checked = true;
          resolve();
        } else {
          reject('You need to acknowledge the denied regions in order to submit orders in denied regions.');
        }
      });
    } else {
      resolve();
    }
  });
}

// TODO: remove this function as it may be deprecated
// moved to loadRegionsMetadata()
function loadRegions(type) {
  addSpinner();
  addButtonSpinner();
  return apiAxios.get('/regions', { params: { type: type || 'public' } }).then(response => {
    removeSpinners();
    return response.data['regions'];
  });
}

export function loadRegionsMetadata(type) {
  addSpinner();
  addButtonSpinner();
  return apiAxios.get('/regions-metadata', { params: { type: type || 'public' } }).then(response => {
    removeSpinners();
    return response.data['regions_metadata'];
  });
}

export function loadRegionsAndUpdateDropdown(type, dropdownMenus) {
  loadRegionsMetadata(type)
    .then(regions => {
      updateRegionsDropdown(regions, dropdownMenus);
    })
    .catch(showErrorFromApiOperation('Load Regions'));
}

export function scrollToPosition() {
  document.documentElement.scrollTop = document.body.scrollTop = pageScrollPosition;
}

// check a given value against a list and return true if value is in the list
export function checkValueAgainstList(value, list) {
  if (typeof list == 'string') {
    list = list.split(',');
  }
  return list && list.includes(value);
}

export function checkIfAnyInList(values, list) {
  for (const elem of list) {
    if (values.indexOf(elem) !== -1) return true;
  }
  return false;
}

export function createJsonCodeBlock(data) {
  const dataParsed = typeof data === 'string' ? JSON.parse(data.replace(/'/g, '"')) : data;
  const codeText = Prism.highlight(JSON.stringify(dataParsed, null, 2), Prism.languages.json, 'json')
    .replace(/"Deny"/g, '<strong style="color:orangered">"Deny"</strong>')
    .replace(/"Allow"/g, '<strong style="color:lightseagreen">"Allow"</strong>');
  return formatPre($('<pre>'), codeText);
}
export function createShellCodeBlock(data) {
  const dataParsed = typeof data === 'string' ? data.replace(/'/g, '"') : data;
  const codeText = Prism.highlight(dataParsed, Prism.languages['shell-session'], 'shell-session');
  return formatPre($('<pre>'), codeText);
}

export function formatPre(pre_element, text_content) {
  let code = [];
  let element = $(pre_element);
  const match = text_content.match(/[\w\W]*?(\n|$)/g);
  for (const code_line of match) {
    if (code_line) code.push(`<div class="line-numbers-row">${code_line}</div>`);
  }
  element.html(code.join('\n'));
  element.addClass('add-line-numbers');
  return element;
}

// UPDATE COLUMN VISIBILITY OF DATATABLES
function configureTable(columnId, tableId) {
  const dt = $(tableId).DataTable({
    retrieve: true,
  });
  const column = dt.column(columnId + ':name');
  column.visible(!column.visible());
}

// Fill the Configure Table dropdown menu for the given table
export function configureTableColumns(tableId, data) {
  const table = document.getElementById(tableId);
  const tr = document.createElement('tr');
  const foot = document.createElement('tfoot');
  foot.appendChild(tr);
  table.appendChild(foot);
  data.forEach(function (item) {
    let th = document.createElement('th');
    if (item.name) {
      th.innerText = item.name;
    }
    tr.appendChild(th);
  });
  $(window).trigger('resize');
}

export function retrieveVisibleFields(tableId, columns) {
  const visibleAttributes = [];
  const datatable = $(tableId).dataTable().api();
  const colLength = columns.length;

  datatable.columns().every(function () {
    const colIndex = this.index();
    if (this.visible() && colIndex !== 0 && colIndex != colLength - 1) {
      visibleAttributes.push(columns[colIndex]['id']);
    }
  });

  return visibleAttributes;
}

export function configureTableConfigurationMenu(dropdownMenuSelector, tableSelector, columns) {
  $(dropdownMenuSelector)
    .empty()
    .append(
      columns.map(item => {
        if (item.name && item.id !== 'actions_col') {
          const checkboxId = 'table-vis-check-' + item.id;
          const checkbox = $('<input type="checkbox">')
            .attr('id', checkboxId)
            .on('change', () => configureTable(item.id, tableSelector));
          return $('<li>')
            .append(
              $('<a class="dropdown-item">').append(
                checkbox,
                $('<label class="w-100">').attr('for', checkboxId).text(item.name),
              ),
            )
            .on('click', event => {
              // clicked on the dropdown item but outside of the checkbox/label
              if (event.target.tagName === 'a') {
                const checked = checkbox.prop('checked');
                checkbox.prop('checked', !checked);
                // change event isn't fired automatically when the checkbox state
                // is changed programmatically, need to trigger this explicitly
                checkbox.trigger('change');
              }
            });
        }
      }),
    );
}

function openLogin() {
  window.open(CONF.awsLoginDetails.webeamLoginUrl, '_blank');
}

export function updateTableKeyBinding(e) {
  const evtobj = window.event ? event : e;
  if (evtobj.keyCode === 73 && evtobj.altKey) showPortalConfig();
  if (evtobj.keyCode === 83 && evtobj.altKey) toggleSidebar();
  if (evtobj.keyCode === 76 && evtobj.altKey) openLogin();

  const tableWrapper = document.getElementsByClassName('table');
  if (tableWrapper[1] && tableWrapper[1].id) {
    const table = $('#' + tableWrapper[1].id).DataTable({ retrieve: true });

    switch (e.which) {
      case 36: // pos1
        updateTablePage(table, 'first');
        break;

      case 37: // left
        updateTablePage(table, 'previous');
        break;

      case 39: // right
        updateTablePage(table, 'next');
        break;
    }
  }
}

function showPortalConfig() {
  const codebox = formatPre($('<pre>'), JSON.stringify(CONF, null, 2));
  swal({
    title: 'Portal Configuration',
    content: codebox[0],
    icon: 'info',
  });
}

function updateTablePage(table, action) {
  if (table.settings()[0]) {
    table.settings()[0].oApi._fnPageChange(table.settings()[0], action, true);
  }
}

// copy text button
function copyTextHandler(event) {
  copyText(event.target.parentElement);
}

export function addCopyButton(object) {
  const text = $(object).text();
  if (text && text !== '-') {
    const copy_button = $('<span class="helperBtn fas fa-copy" title="Copy text to clipboard">');
    copy_button.on('click', copyTextHandler);
    copy_button.appendTo(object);
  }
}

export function copyButtonJSXHelper() {
  return <span class="helperBtn fas fa-copy" onclick={copyTextHandler} title="Copy text to clipboard"></span>;
}

// copy URL button
function copyURLHandler(event) {
  copyURL(event.target.parentElement);
}

export function addCopyUrlButton(object) {
  const text = $(object).text();
  if (text && text !== '-') {
    const copy_button = $('<span class="helperBtn fas fa-copy" title="Copy URL to clipboard">');
    copy_button.on('click', copyURLHandler);
    copy_button.appendTo(object);
  }
}

export function copyURLJSXHelper() {
  return <span class="helperBtn fas fa-copy" onclick={copyURLHandler} title="Copy URL to clipboard"></span>;
}

// load details button
function loadDetailsHandler(event) {
  addSpinners();
  if (event.target.parentElement.innerText.match(/^vpc-.*/)) {
    getVPCDetails(event.target.parentElement.innerText, null);
  } else {
    getAccountDetails(event.target.parentElement);
  }
}

export function addLoadAccountDetailsButton(object) {
  const match = $(object)
    .text()
    .match(/\d{12}/);

  if (match && match[0]) {
    const load_details_button = $('<span class="helperBtn fas fa-search" title="Lookup AWS account details">');
    load_details_button.on('click', loadDetailsHandler);
    load_details_button.appendTo(object);

    const accountIdLink = `${window.location.pathname}?account_id=${match[0]}#accountdetails`;
    $(`<a href="${accountIdLink}" class="helperBtn fas fa-link" title="AWS account details page"></a>`).appendTo(
      object,
    );
  }
}

export function addLoadVPCDetailsButton(object) {
  const match = $(object)
    .text()
    .match(/vpc-[0-9a-z]{17}/);

  if (match && match[0]) {
    const load_details_button = $('<span class="helperBtn fas fa-search" title="Lookup VPC details">');
    load_details_button.on('click', loadDetailsHandler);
    load_details_button.appendTo(object);

    const vpcIdLink = `${window.location.pathname}?vpc_id=${match[0]}#networkdetails`;
    $(`<a href="${vpcIdLink}" class="helperBtn fas fa-link" title="VPC details page"></a>`).appendTo(object);
  }
}

// load order button
function loadOrderHandler(event) {
  getOrderDetails(event.target.parentElement);
}

export function addLoadOrderDetailsButton(object) {
  const text = $(object).html();
  if (text && text !== '-') {
    const load_order_button = $('<span class="helperBtn fas fa-search" title="Lookup Order details">');
    load_order_button.on('click', loadOrderHandler);
    load_order_button.appendTo(object);
  }
}

export function loadOrderJSXHelper() {
  return <span class="helperBtn fas fa-search" onclick={loadOrderHandler} title="Lookup Order details"></span>;
}

// load training button
function loadTrainingHandler(event) {
  getTrainingDetails(event.target.parentElement);
}

export function addLoadTrainingDetailsButton(object) {
  const text = $(object).html();
  if (text && text !== '-') {
    const load_training_button = $('<span class="helperBtn fas fa-search" title="Lookup Training details">');
    load_training_button.on('click', loadTrainingHandler);
    load_training_button.appendTo(object);
  }
}

export function addLinkDXGWIdButton(object) {
  const match = $(object)
    .text()
    .match(/[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/);
  if (match && match[0]) {
    const dxgwIdLink = `${window.location.pathname}?dx_gw_id=${match[0]}#networkdetails`;
    $(`<a href="${dxgwIdLink}" class="helperBtn fas fa-link" title="DXGW details page"></a>`).appendTo(object);
  }
}

export function addLinkFourWheelsIdButton(object) {
  const cell_content = $(object).text();
  if (cell_content) {
    const fourWheelsIdLink = `${window.location.pathname}?cluster_id=${cell_content}#4wheelsdetails`;
    $(`<a href="${fourWheelsIdLink}" class="helperBtn fas fa-link" title="4wheels details page"></a>`).appendTo(object);
  }
}

export function addLinkHostedZoneIdButton(object) {
  const match = $(object)
    .text()
    .match(/[a-z\d.-]{1,60}\.aws\.(cloud|bmw)\.(cloud|bmw)/);
  if (match && match[0]) {
    const hostedZoneIdLink = `${window.location.pathname}?domain=${match[0]}#hostedzonedetails`;
    $(`<a href="${hostedZoneIdLink}" class="helperBtn fas fa-link" title="Hosted Zone details page"></a>`).appendTo(
      object,
    );
  }
}

export function addLinkOrderIdButton(object) {
  const match = $(object)
    .text()
    .match(/[\da-z]{8}-[\da-z]{4}-[\da-z]{4}-[\da-z]{4}-[\da-z]{12}/);
  if (match && match[0]) {
    const orderIdLink = `${window.location.pathname}?order_id=${match[0]}#orderdetails`;
    $(`<a href="${orderIdLink}" class="helperBtn fas fa-link" title="Order details page"></a>`).appendTo(object);
  }
}

export function convertToLink(object) {
  const text = $(object).text();
  if (text.match(/^https:\/\/.*/)) {
    const linkText = text.length > 100 ? text.substring(0, 97) + '...' : text;
    $(object)
      .empty()
      .append($('<a>', { href: text, target: '_blank' }).text(linkText));
  }
}

function addDistributionListWizard(object, data) {
  const text = $(object).text();
  if (text === 'View/Edit Distribution List Members') {
    $(object)
      .empty()
      .append(
        $('<a href="javascript:void(0)">')
          .text(text)
          .on('click', () => loadDistributionListInfo(data.account_id, data.dl_type)),
      );
  }
}

export function loadDistributionListInfo(accountId, dlType) {
  addSpinners();
  const distributionListApi = new DistributionListApi();
  distributionListApi.getDLinfo(accountId, dlType).then(function (dlData) {
    const dlWizard = $('#dlWizard');
    $('#dlWizardTitle').text(dlData.dl_info.mail);
    dlWizard.modal('show');
    $('#dlWizardBody').loadComponent(DistributionListWizard, () => {
      let editableMembersList = false;
      if (dlData.active_updates.length === 0) {
        editableMembersList = true;
      } else {
        const extendedTitle = ' - ' + dlData.active_updates[0].Type + ' ' + dlData.active_updates[0].StatusMessage;
        $('#dlWizardTitle').text(dlData.dl_info.mail + extendedTitle);
      }
      const membersList = formatChildRowDistribuitonListMembers(dlData, editableMembersList);

      if (editableMembersList) {
        const distributionListOperations = new DistributionListOperations(
          distributionListApi,
          accountId,
          dlType,
          membersList,
        );

        dlWizard.on('hidden.bs.modal', function () {
          $('#dlWizardFooter').find('#save-modal').remove();
        });

        $('#dlWizardFooter').append(
          $('<button type="button" id="save-modal">')
            .addClass('btn btn-success')
            .css('display', 'none')
            .text('Save')
            .on('click', function () {
              distributionListOperations.callUpdateDL();
            }),
        );
        $('#add-dl-member-button').on('click', function () {
          $('#save-modal').show();
          distributionListOperations.addMemberToList();
        });
        $('#add-distribution-list-button').on('click', function () {
          $('#save-modal').show();
          distributionListOperations.addDistributionList();
        });
      }

      removeSpinners();
    });
  });
}

$.fn.extend({
  addCopyButton() {
    addCopyButton(this);
    return this;
  },
  addCopyUrlButton() {
    addCopyUrlButton(this);
    return this;
  },
  addLoadAccountDetailsButton() {
    addLoadAccountDetailsButton(this);
    return this;
  },
  convertToLink() {
    convertToLink(this);
    return this;
  },
  addLoadOrderDetailsButton() {
    addLoadOrderDetailsButton(this);
    return this;
  },
  addLoadTrainingDetailsButton() {
    addLoadTrainingDetailsButton(this);
    return this;
  },
  addDistributionListWizard(data) {
    addDistributionListWizard(this, data);
    return this;
  },
  addLoadVPCDetailsButton() {
    addLoadVPCDetailsButton(this);
    return this;
  },
  addLinkDXGWIdButton() {
    addLinkDXGWIdButton(this);
    return this;
  },
});

export function copyText(object) {
  const content = object.innerText;
  navigator.clipboard.writeText(content);
}

export function copyURL(object) {
  const a_element = object.querySelector('a');
  const content = a_element ? a_element.href : 'URL not available';
  navigator.clipboard.writeText(content);
}

export async function getAccountDetails(object) {
  const text = object.innerText;
  const account_id = text.match(/\d{12}/);
  try {
    addSpinners();
    const account_details = await baseApiAxios.getAccount(account_id[0]);
    showAccountDetailsModal(account_details);
  } catch (err) {
    showErrorFromApiOperation('Failed to fetch account details')(err);
  }
  removeSpinners();
}

export const getVPCDetails = async (vpc_id, account_id) => {
  try {
    const vpc = await baseApiAxios.getVPC(vpc_id, account_id, true);
    showAccountVPCModal(vpc);
  } catch (err) {
    showErrorFromApiOperation('Error get VPC ' + vpc_id)(err);
  }
  removeSpinners();
};

export function getAccountById(account_id, callback, callback_param) {
  apiAxios
    .get('accounts/' + account_id)
    .then(response => {
      if (callback) {
        callback(response, callback_param);
      }
    })
    .catch(err => {
      removeSpinners();
      showErrorFromApiOperation('Error get account ' + account_id)(err);
    });
}

function showAccountVPCModal(vpcData) {
  removeSpinners();

  const modalElement = document.getElementById('sharedModal');
  const content = document.getElementById('sharedModalContent');
  createAndAddInfoTable(vpcData, networkInfoFields, content);

  baseApiAxios.getAccountPermissions(vpcData.account_id).then(response => {
    formatChildRowDashboardTemporaryAccessRoles(response, vpcData, content, modalElement);
  });

  $('#sharedModalTitle')[0].innerHTML = '<strong>VPC Details</strong>';
  Modal.getInstance(modalElement).show();
}

export function showAccountDetailsModal(account_information) {
  removeSpinners();

  const modalElement = document.getElementById('sharedModal');
  const content = document.getElementById('sharedModalContent');
  createAndAddInfoTable(account_information, accountInfoFields, content);

  baseApiAxios.getAccountPermissions(account_information.account_id).then(response => {
    formatChildRowDashboardTemporaryAccessRoles(response, account_information, content, modalElement);
  });

  $('#sharedModalTitle')[0].innerHTML = '<strong>Account Details</strong>';
  Modal.getInstance(modalElement).show();
}

export function createSwitchRoleLink(roleName, accountId, accountFriendlyName, accountStage) {
  let switchRoleLink = CONF.awsLoginDetails.switchRoleUrl + 'switchrole?account=' + accountId + '&roleName=' + roleName;

  if (accountFriendlyName) {
    const friendlyName = encodeURIComponent(accountFriendlyName.substring(0, 64).trim());
    switchRoleLink += '&displayName=' + friendlyName;
  }

  let color;
  switch (accountStage) {
    case 'int':
      color = 'FAD791';
      break;
    case 'test':
    case 'dev':
      color = 'B7CA9D';
      break;
    case 'basic':
      color = '99BCE3';
      break;
    default:
      color = 'F2B0A9';
  }
  switchRoleLink += '&color=' + color;

  return switchRoleLink;
}

export function formatChildRowDashboardTemporaryAccessRoles(roles, account_information, content, modal) {
  removeSpinners();

  const temporaryRoles = roles.temporary_roles || [];
  const federatedUserRoles = roles.permanent_roles?.federated_user_roles || [];

  // We add the table with permanent and temporary roles only if there are any roles available
  if (temporaryRoles.length > 0 || federatedUserRoles.length > 0) {
    $('<div class="role-container">')
      .append(
        $('<table class="table table-hover dataTable row-border compressed roles-table">').append(
          // We want to see 3 columns if the user has access to temporary roles, as we need to show the button to
          // request the login for those temporary links. We only show 2 columns if the user has no temporary roles.
          $('<thead class="roles-table">').append(
            '<th class="table table-hover dataTable row-border nowrap compressed">IAM Role</th>',
            temporaryRoles.length > 0
              ? '<th class="table table-hover dataTable row-border nowrap compressed">Action</th>'
              : '',
            '<th class="table table-hover dataTable row-border nowrap compressed">Link</th>',
          ),
          $('<tbody class="roles-table">').append(
            // This map will add all permanent, federated role names and assume role links for the given account
            // As the table will have only 2 columns for users without temporary role access but 3 columns for users
            // with temporary role access, we have to make it flexible.
            federatedUserRoles.map(roleName => {
              return $('<tr role="row" class="compressed">').append(
                $('<td class="name small-width">').text(roleName).addCopyButton(),
                temporaryRoles.length > 0 ? $('<td class="name small-width">').text('-') : '',
                $('<td class="name small-width">')
                  .append(
                    <a
                      href={createSwitchRoleLink(
                        roleName,
                        account_information.account_id,
                        account_information.account_friendly_name,
                        account_information.account_stage,
                      )}
                      target="_blank"
                      rel="noopener noreferrer">
                      Switch Role
                    </a>,
                  )
                  .addCopyUrlButton(),
              );
            }),
            // This map will add all temporary role names, the button to request the link and the column for the link
            temporaryRoles.map(roleName => {
              return $('<tr role="row" class="compressed">').append(
                $('<td class="name small-width">').text(roleName).addCopyButton(),
                $('<td class="value small-width">').append(
                  $('<button class="btn-sm space text-nowrap">')
                    .on('click', event => {
                      showTemporaryRoleCommentModal(event.target, account_information, roleName, modal);
                    })
                    .html(
                      '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="fas fa-user"></span> Get Temporary Access',
                    ),
                ),
                $('<td class="value temp-link-column">').html('***').addCopyUrlButton(),
              );
            }),
          ),
        ),
      )
      .appendTo(content);
  }
}

function closeTemporaryRoleModel(get_link_button, modal_button, account_information, roleName, modal) {
  $(this).off('hide.bs.modal');
  $('#temporaryRoleModal').modal('hide');
  if (modal) {
    $('#sharedModal').modal('show');
  }
  if (!modal_button.is('#close-modal-button')) {
    saveTemporaryRoleComment(get_link_button, modal_button, account_information, roleName);
  }
}

function saveTemporaryRoleComment(get_link_button, modal_button, account_information, roleName) {
  const comment = document.getElementById('temp-access-comment').value;
  if (comment) {
    const clipboard = modal_button.is('#copy-modal-button');
    getTemporaryRoleAccessLink(get_link_button, account_information, roleName, comment, clipboard);
  } else {
    displayErrorPopup(null, 'You have to provide a reason for your request');
  }
}

export function showTemporaryRoleCommentModal(get_link_button, account_information, roleName, modal) {
  $('#temporaryRoleModalContent')
    .empty()
    .append(<TemporaryRoleComment />);

  // loading
  const temporaryRoleModal = $('#temporaryRoleModal');
  temporaryRoleModal.on('shown.bs.modal', function () {
    $('#temp-access-comment').trigger('focus').trigger('select');
    $('#copy-modal-button').prop('disabled', true);
    $('#save-modal-button').prop('disabled', true);

    $('#close-modal-button,#save-modal-button,#copy-modal-button')
      .off()
      .on('click', event => {
        closeTemporaryRoleModel(get_link_button, $(event.target), account_information, roleName, modal);
      });

    $('#temp-access-comment').on('keyup', function (event) {
      $('#save-modal-button').prop('disabled', this.value === '');
      $('#copy-modal-button').prop('disabled', this.value === '');

      // handle keyboard
      if (event.key === 'Enter') {
        $('#save-modal-button').trigger('click');
      } else if (event.key === 'Esc') {
        $('#close-modal-button').trigger('click');
      }
    });
  });
  temporaryRoleModal.on('hidden.bs.modal', function () {
    get_link_button.classList.remove('loading-animation');
  });
  get_link_button.classList.add('loading-animation');
  const modalElement = document.getElementById('temporaryRoleModal');
  if (modal) {
    $('#sharedModal').modal('hide');
  }
  Modal.getInstance(modalElement).show();
}

function getTemporaryRoleAccessLink(get_link_button, account_information, role_name, comment, clipboard) {
  let aws_partition = 'aws';

  if (account_information.region_category === 'China') {
    aws_partition = 'aws-cn';
  }

  const accountId = account_information.account_id ?? account_information.aws_account_id;

  const payload = {
    account_id: accountId,
    region_name: account_information.aws_region ?? account_information.region_name,
    role_name: role_name,
    aws_partition: aws_partition,
    description: 'Get temporary access to IAM role ' + role_name,
    comment,
  };

  baseApiAxios
    .post('/accounts/' + accountId + '/temporary-access', payload)
    .then(response => {
      const table_row = $(get_link_button).closest('tr');
      const table_cell = table_row.find('.temp-link-column');

      $(table_cell).text(response.login_link);
      convertToLink(table_cell);
      addCopyUrlButton(table_cell);

      if (clipboard) {
        copyURL(table_cell[0]);
      }

      get_link_button.classList.remove('loading-animation');
    })
    .catch(showError)
    .finally(() => {
      removeSpinners();
    });

  addSpinners();
}

export function getOrderDetails(object) {
  const order_id = object.innerText;

  if (order_id) {
    baseApiAxios
      .getOrder(order_id)
      .then(result => {
        const order_data = result.order;
        const content = document.getElementById('sharedModalContent');
        createAndAddInfoTable(order_data, orderInfoFields, content);
        document.getElementById('sharedModalTitle').innerHTML = '<strong>Order Details</strong>';
        const modalElement = document.getElementById('sharedModal');
        Modal.getInstance(modalElement).show();
      })
      .catch(displayErrorPopup)
      .finally(() => removeSpinners());
  }
}

export function getTrainingDetails(object) {
  const trainingId = object.innerText;

  if (trainingId) {
    apiAxios
      .get('/trainings/' + trainingId)
      .then(getResponse => {
        const trainingData = getResponse.data.training;
        const content = document.getElementById('sharedModalContent');
        createAndAddInfoTable(trainingData, trainingInfoFields, content);
        $('#sharedModalTitle')[0].innerHTML = '<strong>Training Details</strong>';
        const modalElement = document.getElementById('sharedModal');
        Modal.getInstance(modalElement).show();
      })
      .catch(err => {
        showErrorFromApiOperation('Failed to fetch training details')(err);
      })
      .finally(() => {
        removeSpinners();
      });
  }
}

export function InfoTable({ data, rowInfos, prefix }) {
  return (
    <table class="table table-hover dataTable row-border compressed">
      <thead>
        <th class="table table-hover dataTable row-border nowrap compressed">Name</th>
        <th class="table table-hover dataTable row-border nowrap compressed">Value</th>
      </thead>
      <tbody>
        {rowInfos.map(item => {
          const resolved = resolve(item.Value, data);
          const valueText = typeof resolved !== 'boolean' ? resolved || '-' : resolved.toString();
          let tdValue = $('<td class="value">');
          if (item.HTML) {
            tdValue.append(valueText.split('\n').map(line => $('<div>').text(line)));
          } else {
            tdValue
              .text(valueText)
              .convertToLink()
              .addCopyButton()
              .addLoadAccountDetailsButton()
              .addLoadVPCDetailsButton()
              .addDistributionListWizard(data);
            if (item.Value === 'order_id' || item.Value === 'id') {
              tdValue.addLoadOrderDetailsButton();
            }
            if (item.Value === 'training_id') {
              tdValue.addLoadTrainingDetailsButton();
            }
          }
          return (
            <tr role="row" class={'compressed' + (prefix ? ` ${prefix}-${item.Value}` : '')}>
              <td class="name">{item.Name}</td>
              {tdValue}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export function renderInfoTable(data, rowInfos) {
  return <InfoTable data={data} rowInfos={rowInfos} />;
}

export function createAndAddInfoTable(data, rowInfos, parent) {
  $(parent).empty().append(renderInfoTable(data, rowInfos));
}

function resolve(path, obj, separator = '.') {
  const properties = path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], obj);
}

export function InfoTableWide({ data, colInfos, rowNumbers = false, prefix }) {
  const colInfoObjects = colInfos.map(element => (typeof element === 'string' ? { Name: element } : element));
  return (
    <table class="table table-hover dataTable row-border nowrap compressed">
      <thead>
        {rowNumbers && <th class="table table-hover dataTable row-border nowrap compressed index">#</th>}
        {colInfoObjects.map(key => (
          <th class="table table-hover dataTable row-border nowrap compressed">{key.Name}</th>
        ))}
      </thead>
      <tbody>
        {data.map((item, num) => (
          <tr role="row" class="compressed">
            {rowNumbers && <td class="index">{num + 1}</td>}
            {colInfoObjects.map(key => {
              const resolved = resolve(key.Name, item);
              if (['Actions', 'actions'].indexOf(key.Name) >= 0 && resolved) {
                return <td style="width: 50px">{resolved}</td>;
              } else if (resolved) {
                return $(prefix ? `<td class="${prefix}-${num}-${key.Ref || key.Name}">` : '<td>')
                  .text(resolved)
                  .addCopyButton()
                  .addLoadAccountDetailsButton()
                  .addLoadVPCDetailsButton()[0];
              } else {
                return <td>-</td>;
              }
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function createAndAddInfoTableWide(data, colInfos, parent, rowNumbers) {
  $(parent)
    .empty()
    .append(<InfoTableWide data={data} colInfos={colInfos} rowNumbers={rowNumbers} />);
}

export function isNullOrEmpty(str) {
  return str === null || str === '' || str === undefined;
}

export function hasAccess(accountId, adminPermissions) {
  return (
    (adminPermissions &&
      adminPermissions.length > 0 &&
      checkIfAnyInList(localStorage.permissions.split(','), adminPermissions)) ||
    localStorage.accessible_accounts.includes(accountId)
  );
}

export function checkUserAccess(adminPermissions, accountIds) {
  return accountIds.some(accountId => hasAccess(accountId, adminPermissions));
}

export function createPortalOrder(orderPayload) {
  baseApiAxios
    .createOrder(orderPayload)
    .then(result => {
      addToast('Create Order', result.message, 6000);
    })
    .catch(displayErrorPopup)
    .finally(() => removeSpinners());
}

// Regular Expressions for parsing tags and attributes
const SURROGATE_PAIR_REGEXP = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
// Match everything outside of normal chars and " (quote character)
const NON_ALPHANUMERIC_REGEXP = /([^#\-~ |!])/g;

/**
 * Escapes all potentially dangerous characters, so that the
 * resulting string can be safely inserted into attribute or
 * element text.
 * @param value
 * @returns {string} escaped text
 */
export function encodeEntities(value) {
  if (typeof value === 'boolean') {
    // If there are booleans in the table, the excel/csv export is not working anymore
    return String(value);
  } else if (typeof value !== 'string') {
    return value;
  }
  return value
    .replace(/&/g, '&amp;')
    .replace(SURROGATE_PAIR_REGEXP, function (surrogateValue) {
      const hi = surrogateValue.charCodeAt(0);
      const low = surrogateValue.charCodeAt(1);
      return '&#' + ((hi - 0xd800) * 0x400 + (low - 0xdc00) + 0x10000) + ';';
    })
    .replace(NON_ALPHANUMERIC_REGEXP, function (nonAlphanumericValue) {
      return '&#' + nonAlphanumericValue.charCodeAt(0) + ';';
    })
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

/**
 * Wraps the async operation so that a "loading spinner" is laid over the entire
 * page while it is running.
 *
 * @param f {Function} a function representing the operation, should return a Promise
 * @returns {Promise|any} the result of the function `f`
 */
export function withLoadingSpinner(f) {
  addSpinner();

  function cleanupSpinner() {
    removeSpinners();
  }

  try {
    const result = f();
    if (typeof result.then === 'function') {
      // don't use finally here because it swallows the Promise result
      return result.then(
        value => {
          cleanupSpinner();
          return value;
        },
        error => {
          cleanupSpinner();
          return Promise.reject(error);
        },
      );
    } else {
      // safeguard if function f does not return a promise
      cleanupSpinner();
      return result;
    }
  } catch (e) {
    // safeguard if function f throws an exception
    cleanupSpinner();
  }
}

export function showErrorFromApiOperation(errorTitle) {
  return function (error) {
    let errorContent;
    if (error.response) {
      console.warn(errorTitle + ': error response from API', error.response);
      const errorData = error.response.data;
      errorContent = $('<div>')
        .append(
          $('<p>').text((errorData && errorData.message) || error.toString()),
          errorData.errors &&
            errorData.errors.length &&
            $('<ul>').append(errorData.errors.map(errorItem => $('<li>').text(errorItem))),
        )
        .get()[0];
    } else {
      console.error(error);
      errorContent = error.toString();
    }
    return swal({
      title: errorTitle || 'Error:',
      content: errorContent,
      icon: 'error',
    });
  };
}

export function addToast(subject, message, delay) {
  let toastContainer = document.getElementById('toastPlacement');

  let toast = document.createElement('div');
  toast.setAttribute('class', 'toast');
  toast.setAttribute('role', 'alert');
  toast.setAttribute('aria-live', 'assertive');
  toast.setAttribute('aria-atomic', 'true');
  toast.setAttribute('data-bs-delay', delay);

  let toastHeader = document.createElement('div');
  toastHeader.setAttribute('class', 'toast-header');
  toast.appendChild(toastHeader);

  let toastHeaderText = document.createElement('strong');
  toastHeaderText.setAttribute('class', 'me-auto');
  toastHeaderText.textContent = subject;
  toastHeader.appendChild(toastHeaderText);

  let toastHeaderButton = document.createElement('button');
  toastHeaderButton.setAttribute('class', 'btn-close');
  toastHeaderButton.setAttribute('type', 'button');
  toastHeaderButton.setAttribute('data-bs-dismiss', 'toast');
  toastHeaderButton.setAttribute('aria-label', 'Close');
  toastHeader.appendChild(toastHeaderButton);

  let toastBody = document.createElement('div');
  toastBody.setAttribute('class', 'toast-body');
  toastBody.textContent = message;
  toast.appendChild(toastBody);

  toast.addEventListener('hidden.bs.toast', function () {
    toastContainer.removeChild(toast);
  });

  toastContainer.appendChild(toast);
  showToast();
}

function showToast() {
  const toastElList = [].slice.call(document.querySelectorAll('.toast'));
  const toastList = toastElList.map(function (toastEl) {
    return new Toast(toastEl);
  });
  toastList.forEach(toast => toast.show());
}

// Row details stuff that could be the same for all kind of requests

/**
 *
 * @param {*} row
 * @param {object} data
 * @param {*} tabDefinition
 */
export function formatTableChildRow(row, data, tabDefinition) {
  let detailContainer = document.createElement('div');
  detailContainer.setAttribute('class', 'detailsContainer');

  let nav = document.createElement('ul');
  nav.setAttribute('class', 'nav nav-tabs');
  detailContainer.appendChild(nav);

  let navContent = document.createElement('div');
  navContent.setAttribute('class', 'tab-content detailsTab');
  detailContainer.appendChild(navContent);

  tabDefinition.forEach(item => {
    const navItem = document.createElement('li');
    navItem.setAttribute('class', item['Default'] ? 'nav-item active' : 'nav-item');
    nav.appendChild(navItem);

    const navLink = document.createElement('a');
    navLink.setAttribute('class', item['Default'] ? 'nav-link active' : 'nav-link');
    navLink.setAttribute('aria-selected', !!item['Default']);
    navLink.setAttribute('aria-expanded', !!item['Default']);
    navLink.setAttribute('role', 'tab');
    navLink.setAttribute('data-bs-toggle', 'tab');
    navLink.innerText = item['Name'];
    navItem.appendChild(navLink);

    const navItemBody = document.createElement('div');
    navItemBody.setAttribute('class', item['Default'] ? 'tab-pane fade active show' : 'tab-pane fade');
    navItemBody.setAttribute('role', 'tabpanel');
    navContent.appendChild(navItemBody);

    if (item.UidDataAttribute && data[item.UidDataAttribute]) {
      navLink.setAttribute('href', '#' + item['Value'] + data[item.UidDataAttribute]);
      navItemBody.setAttribute('id', item['Value'] + data[item.UidDataAttribute]);
    } else {
      const randomId = Math.random().toString(36).substring(2, 9);
      navLink.setAttribute('href', '#' + item['Value'] + randomId);
      navItemBody.setAttribute('id', item['Value'] + randomId);
    }

    const detailContainerRoles = document.createElement('div');
    detailContainerRoles.setAttribute('class', 'row');
    detailContainerRoles.setAttribute('name', item['AttributeName']);
    navItemBody.appendChild(detailContainerRoles);

    item['Callback'](data, detailContainerRoles, navLink);
  });

  row.child(detailContainer).show();
  row.child()[0].setAttribute('class', 'rowDetails');
}

/**
 *
 * @param {*} parent
 * @param {string?} classes
 * @returns
 */
export function addContentDiv(parent, classes) {
  let content = document.createElement('div');
  content.setAttribute('class', classes ? classes : 'col-lg-12 col-md-12');
  parent.appendChild(content);
  return content;
}

/**
 *
 * @param parent {HTMLElement}
 * @param text {string}
 * @param classes {string?}
 */
export function addContentHeader(parent, text, classes) {
  let header = document.createElement(classes ?? 'h4');
  header.innerText = text;
  parent.appendChild(header);
}

/**
 *
 * @param parent {HTMLElement}
 * @param icon {string}
 * @param title {string}
 * @param callbackFunction {function}
 * @param callbackData {any?}
 * @param classes {string?}
 */
export function addContentButtonWithTooltip(
  parent,
  title,
  icon,
  callbackFunction,
  callbackData,
  disabled = 'true',
  classes,
) {
  const button = document.createElement('button');
  button.setAttribute('class', classes ? classes : 'btn btn-custom btn-xs');
  button.setAttribute('data-bs-toggle', 'tooltip');
  button.setAttribute('title', title);
  if (disabled) {
    button.setAttribute('disabled', 'true');
  }
  button.onclick = function () {
    callbackFunction(callbackData);
  };
  button.innerHTML = '<span class="' + icon + '"></span>';
  parent.appendChild(button);
}

/**
 *
 * @param {*} parent
 * @param {*} data
 * @param {*} headerText
 */
export function addRawData(parent, data, headerText) {
  const rawContainer = document.createElement('div');
  rawContainer.setAttribute('class', 'detailsContent');
  parent.appendChild(rawContainer);

  const header = document.createElement('h4');
  header.innerText = headerText;
  rawContainer.appendChild(header);

  const preCode = document.createElement('pre');
  rawContainer.appendChild(preCode);

  let payload_json;
  if (typeof data === 'string') {
    const payload = data.replace(/'/g, '"');
    payload_json = JSON.parse(payload);
  } else {
    payload_json = data;
  }

  const payload_string = JSON.stringify(payload_json, null, 2);
  formatPre(preCode, payload_string);
}

/**
 *
 * @param {*} n
 */
export function showTab(n) {
  const x = document.getElementsByClassName('tab');
  for (let tab of x) {
    tab.style.display = 'none';
  }
  x[n].style.display = 'block';
}

// #########################################################################
// ITSM STUFF
// #########################################################################

export function createITSMTicket(body, _submitButtonId) {
  return apiAxios
    .post('/itsm', body, {
      headers: {
        'Content-Type': 'application/json',
      },
      transformRequest: x => JSON.stringify(x),
    })
    .then(function (response) {
      if (response.status === 200) {
        swal({
          title: 'Incident successfully created',
          text: 'Incident ID: ' + response.data.incident_id,
          icon: 'success',
          closeOnClickOutside: false,
        });
      } else {
        if ('id' in response.data) {
          swal({
            title: 'Incident successfully created',
            text:
              'Incident ID: ' +
              response.data.incident_id +
              '\n\n Your file was not attached due to a server error. Please contact support.',
            icon: 'success',
            closeOnClickOutside: false,
          });
        } else {
          displayErrorPopup(null, response.data.message);
        }
      }
    })
    .catch(showErrorFromApiOperation('Error creating Incident'));
}

/**
 * (javascript) returns the given object with keys sorted alphanumerically.
 * @param {any} obj the object to sort
 * @returns {any} the sorted object
 */
export const sort = obj =>
  Object.keys(obj)
    .sort()
    .reduce((acc, c) => {
      acc[c] = obj[c];
      return acc;
    }, {});

/**
 * @name refreshRow
 * @description it refresh a single row in a JQuery data table, triggering also the createdCell functions
 * @param {*} row a jQuery data table row
 */
export function refreshRow(row) {
  row.invalidate();
  row.context[0].aoColumns
    .filter(function (f) {
      return f.fnCreatedCell != null;
    })
    .forEach(function (o) {
      o.fnCreatedCell($(row.node()).find('td').eq(o.idx), row.data()[o.data], row.data(), row.index(), o.idx);
    });
}

export function OverviewSwitch({ targets, localStorageItem }) {
  const currentState = localStorage.getItem(localStorageItem) === 'true' ? true : false;
  const input = $('<input/>', {
    class: 'form-check-input portal-check-input',
    type: 'checkbox',
    id: 'show-overview-switch',
    'data-bs-toggle': 'collapse',
    'data-bs-target': targets,
    checked: !currentState,
  });

  return (
    <div id="show-overview-div" class="col col-auto align-self-center">
      <div class="form-check form-switch">
        {input}
        <label class="form-check-label" for="show-overview-switch">
          Show Overview
        </label>
      </div>
    </div>
  );
}

export function toggleOverview(localStorageItem) {
  let currentState = localStorage.getItem(localStorageItem) === 'true' ? true : false;

  $('#mainPage .collapse')
    .on('hidden.bs.collapse', function () {
      if (this.id) {
        localStorage.setItem(localStorageItem, true);
        currentState = true;
      }
    })
    .on('shown.bs.collapse', function () {
      if (this.id) {
        localStorage.setItem(localStorageItem, false);
        currentState = false;
      }
    })
    .each(function () {
      if (localStorage.getItem(localStorageItem) === 'true') {
        $(this).collapse('hide');
      }
    });

  $('#show-overview-switch').attr('checked', !currentState);
}

/**
 * Adds a spinner animation to the submit button and disables the button
 * @param {string} buttonId
 */
export function submitButtonLoading(buttonId) {
  const submitButton = $('#' + buttonId);
  const spinner = <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />;
  submitButton.parent().css('cursor', 'not-allowed');
  submitButton
    .empty()
    .append(spinner)
    .append(' ' + submitButton.val())
    .prop('disabled', true);
}

/**
 * Reset the button text (e.g. removes the spinner animation) and enables the button
 * @param {string} buttonId
 */
export function submitButtonReset(buttonId) {
  const submitButton = $('#' + buttonId);
  submitButton.empty().append(submitButton.val()).prop('disabled', false);
}

/**
 *
 * @returns
 */
export const generateRandom = () => {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  return crypto.getRandomValues(array)[0];
};

/**
 *
 * @param {*} param0
 * @returns
 */
export function RowDetails({ tabDefinition, rowData, tabIdAttribute }) {
  const navItemsAndContents = tabDefinition.map(item => {
    const uuid = rowData[tabIdAttribute] ?? generateRandom();

    const tabDetails = item.renderDetails({ rowData });

    const navItem = (
      <li class={['nav-item', item.active && 'active']}>
        <a
          href={'#' + item.Value + uuid}
          role="tab"
          data-bs-toggle="tab"
          class={['nav-link', item.active ? 'active' : '', tabDetails ? '' : 'disabled']}
          aria-selected={item.active}
          aria-expanded={item.active}>
          {item.Name}
        </a>
      </li>
    );

    const tabContent = (
      <div id={item.Value + uuid} class={item.active ? 'tab-pane fade active show' : 'tab-pane fade'} role="tabppanel">
        <div class="row">{tabDetails}</div>
      </div>
    );

    return { navItem, tabContent };
  });

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">{navItemsAndContents.map(({ navItem }) => navItem)}</ul>
      <div class="tab-content detailsTab">{navItemsAndContents.map(({ tabContent }) => tabContent)}</div>
    </div>
  );
}

/**
 *
 * @param {*} row
 */
export function addRowLoadingAnimation(row) {
  const animation = (
    <div class="row-loading-animation text-center">
      <div class="spinner-grow text-secondary m-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
  row.child(animation).show();
  row.child()[0].setAttribute('class', 'rowDetails');
}

/**
 *
 * @param {*} param0
 */
export function rowDetailsClickHandler({ tableId, rowDetailCallback }) {
  const dt = $('#' + tableId).DataTable();

  $('#' + tableId + ' tbody').on('click', 'tr td.details-control', function () {
    const tr = $(this).closest('tr');
    const row = dt.row(tr);
    if (row.child.isShown()) {
      tr.removeClass('details');
      row.child.hide();
    } else {
      tr.addClass('details');
      addRowLoadingAnimation(row);
      rowDetailCallback(row);
    }
  });
}

/**
 * Delete all active options from the given select
 *
 * @param {JSX.IntrinsicElements.select} select - the target select object
 */
export function removeDisabledOptions(select) {
  const selectInput = select instanceof jQuery ? select[0] : select;

  // Remove all optgroups if there are any. This will automatically remove all the options below the optgroups
  selectInput.querySelectorAll('optgroup:not(.defaultDisabled)').forEach(item => {
    selectInput.removeChild(item);
  });

  // Remove all options from the selectpicker if there's not optgroup in between
  selectInput.querySelectorAll('option:not(.defaultDisabled)').forEach(item => {
    selectInput.removeChild(item);
  });
}

/**
 * Function which checks the frontendConfig if the user has the required permissions to
 * view the content, which we identify by its id
 *
 * @param {*} id - the content ID for which the function checks the user's permissions
 */
export async function isAllowedToViewContent(id) {
  const localPermissions = localStorage.permissions.split(',');
  const requiredPermissions = process.env.PORTAL_CONFIG.menuPermissions[id];

  allowedtoViewContent[id] =
    localPermissions.filter(localPermission => requiredPermissions.includes(localPermission)).length > 0;
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function updateFormState(state, fieldIds) {
  let newState = state;
  let formFields = {};
  fieldIds.forEach(id => (formFields[id] = document.getElementById(id)));
  const invalidFields = fieldIds.filter(id => !formFields[id].validity?.valid);
  if (invalidFields.length) throw `Invalid Field Values: ${invalidFields.join(', ')}`;
  fieldIds.forEach(id => {
    const $formField = $(formFields[id]);
    if ($formField.is('[type="checkbox"]')) {
      newState[id] = $formField.is(':checked');
    } else if ($formField.is('[type="number"]')) {
      newState[id] = parseInt($formField.val(), 10);
    } else {
      newState[id] = $formField.val();
    }
  });
  return newState;
}

export async function proceedToNextStep(callbackFunctions, direction = 1) {
  const visuContainer = document.querySelector('.process-steps-container');
  const formContainer = document.querySelector('.form-steps-container');
  if (visuContainer && formContainer) {
    const visuSteps = Array.from(visuContainer.querySelectorAll('.process-step'));
    const visuStepIndex = visuSteps.findIndex(item => item.classList.contains('current-step'));
    const formSteps = Array.from(formContainer.querySelectorAll('.form-step'));
    const formStepIndex = formSteps.findIndex(item => item.classList.contains('current-step'));

    if (visuStepIndex === formStepIndex) {
      const targetId =
        direction === 1 || formStepIndex === 0
          ? formSteps[formStepIndex].id
          : formSteps[Math.max(formStepIndex - 2, 0)]?.id;
      if (targetId in callbackFunctions) {
        addSpinner();
        addButtonSpinner();
        try {
          await callbackFunctions[targetId]();
        } catch (err) {
          console.error(err);
          if (callbackFunctions['error']) callbackFunctions['error'](err);
          removeSpinners();
          return;
        }
        removeSpinners();
      }
      if ('backButton' in callbackFunctions) {
        callbackFunctions['backButton'].disabled = false;
        callbackFunctions['backButton'].classList.toggle(
          'd-none',
          (direction === -1 && formStepIndex === 1) || (direction === 1 && formStepIndex === formSteps.length - 1),
        );
      }
      if (direction === -1 || (visuSteps.length - 1 !== visuStepIndex && formSteps.length - 1 !== formStepIndex)) {
        visuSteps.forEach((stepElement, index) => {
          if (index === visuStepIndex) {
            if (direction === -1) stepElement.classList.remove('process-step--fill');
            stepElement.classList.remove('current-step');
          } else if (index === visuStepIndex + 1 * direction) {
            stepElement.classList.add('process-step--fill', 'current-step');
          }
        });
        formSteps.forEach((stepElement, index) => {
          if (index === formStepIndex) {
            stepElement.classList.remove('current-step');
          } else if (index === formStepIndex + 1 * direction) {
            stepElement.classList.add('current-step');
          }
        });
      }
    } else {
      console.error('Step indices for form container and visualization are different.');
    }
  }
}

export function jumpToFirstStep() {
  const visuContainer = document.querySelector('.process-steps-container');
  const formContainer = document.querySelector('.form-steps-container');
  if (visuContainer && formContainer) {
    const visuSteps = Array.from(visuContainer.querySelectorAll('.process-step'));
    const formSteps = Array.from(formContainer.querySelectorAll('.form-step'));
    visuSteps.forEach(item => item.classList.remove('process-step--fill', 'current-step'));
    visuSteps[0].classList.add('process-step--fill', 'current-step');
    formSteps.forEach(item => item.classList.remove('current-step'));
    formSteps[0].classList.add('current-step');
  }
}

export function hideMessages() {
  const formError = document.getElementById('form-error');
  if (formError) {
    formError.innerHTML = '<ul></ul>';
    formError.style.display = 'none';
  }
  const formSuccess = document.getElementById('form-success');
  if (formSuccess) {
    formSuccess.innerHTML = '<ul></ul>';
    formSuccess.style.display = 'none';
  }
}

export function createDisplayErrorFunction(msg, append = true) {
  return error => {
    const formError = document.getElementById('form-error');
    const formErrorList = formError.querySelector('ul');
    if (!append) formErrorList.innerHTML = '';
    const listElement = document.createElement('li');
    if (typeof error === 'string') {
      listElement.innerText = error;
    } else {
      listElement.innerHTML = `${msg} ${error.message || JSON.stringify(error, null, 2)}`;
    }
    formErrorList.appendChild(listElement);
    formError.style.display = '';
  };
}

export function displayError(html, append = true) {
  const formError = document.getElementById('form-error');
  const formErrorList = formError.querySelector('ul');
  if (!append) formErrorList.innerHTML = '';
  const listElement = document.createElement('li');
  listElement.innerHTML = html;
  formErrorList.appendChild(listElement);
  formError.style.display = '';
}

export function displaySuccess(html, append = true) {
  const formSuccess = document.getElementById('form-success');
  const formSuccessList = formSuccess.querySelector('ul');
  if (!append) formSuccessList.innerHTML = '';
  const listElement = document.createElement('li');
  listElement.innerHTML = html;
  formSuccessList.appendChild(listElement);
  formSuccess.style.display = '';
}

export const displayErrorPopup = (err, text) => {
  if (err) console.error(err);
  return swal({
    title: 'Error:',
    text: text || err.message,
    icon: 'error',
  });
};

export const displaySuccessPopup = (text, title) => {
  return swal({
    title: title || 'Your request was successful.',
    text,
    icon: 'success',
  });
};

export const displayInfoPopup = (text, title) => {
  return swal({
    title: title,
    text,
    icon: 'info',
  });
};
